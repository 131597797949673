import React from 'react'

// Elements
import ParseContent from 'components/shared/ParseContent'
import FlexContentHandler from 'components/shared/FlexContentHandler'
import Share from 'components/elements/Share'

// Third Party
import styled from 'styled-components'
import usePost from 'hooks/usePost'
import Plaatjie from '@ubo/plaatjie'
import FormDuuf from 'components/related/Form'
import { ButtonSecondaryCSS } from 'components/elements/Buttons/ButtonSecondary'
import { ButtonPrimaryCSS } from 'components/elements/Buttons/ButtonPrimary'

interface WithSidebarProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
  location?: any
  pageContext?: any
}

const StyledFormDuuf = styled(FormDuuf)`
  position: relative;

  & .form-duuf-group {
    margin-bottom: 1.5rem;
    max-width: 400px;
    position: relative;
  }

  & .form-duuf-textarea,
  & .form-duuf-input {
    width: 100%;
    border-radius: 10px;
    border: none;
    min-height: 50px;
    padding: 0 15px;
    font-size: 16px;

    &::placeholder {
      color: ${({ theme }) => theme.color.grey};
    }
  }

  & .form-duuf-textarea {
    padding: 15px;
    height: 160px;
  }

  & .form-duuf-group label.form-duuf-label {
    margin-bottom: 0.5rem !important;
  }

  & .form-duuf-group .form-duuf-choices-input_9 > div {
    margin-bottom: 0.5rem;
  }

  & .form-duuf-group .form-duuf-choices-input_9 label {
    font-size: 14px;
  }

  & .form-duuf-file-button {
    ${ButtonSecondaryCSS};
  }

  & .form-duuf-file-name {
    margin-left: 0.5rem;
  }

  & .form-duuf-checkbox-label {
    margin-left: 0.5rem;
    margin-bottom: 0;
  }

  & .form-duuf-label-mandatory {
    color: ${({ theme }) => theme.color.secondary};
  }

  & .form-duuf-error {
    color: tomato;
    position: absolute;
    right: 15px;
    top: 13px;
    font-size: 14px;

    & + input,
    + textarea {
      border: 1px solid tomato;
    }
  }

  & .form-duuf-submit {
    ${ButtonPrimaryCSS};

    &-disabled {
      opacity: 0.5;
    }
  }

  /* & .form-duuf-confirm {
    color: ${({ theme }) => theme.color.primary};
    font-size: 20px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
  } */

  & .form-duuf-something-wrong {
    color: tomato;
  }

  & .form-duuf-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
  }
`

const StyledWithFragments = styled.div`
  & p,
  li {
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.color.grey};

    & b,
    strong {
      /* color: ${({ theme }) => theme.color.darkish}; */
      color: inherit;
    }
  }

  & a {
    color: ${({ theme }) => theme.color.primary};

    &:hover {
      text-decoration: underline;
    }
  }

  & img {
    object-fit: contain !important;
  }

  & li {
    list-style: none;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      left: -20px;
      background-color: ${({ theme }) => theme.color.primary};
      height: 8px;
      width: 8px;
      border-radius: 4px;
    }
  }

  & blockquote {
    padding: 80px 0 20px 60px;
    position: relative;

    & em {
      font-size: 24px !important;
    }

    & p {
      font-size: 15px !important;
      color: ${({ theme }) => theme.color.darkish};
      font-family: ${({ theme }) => theme.font.family.secondary};
      font-weight: ${({ theme }) => theme.font.weight.bold};
      padding-bottom: 20px;

      &:first-of-type {
        line-height: 34px;
      }

      & b,
      strong {
        color: ${({ theme }) => theme.color.primary};
        font-size: 16px;
        line-height: 140%;
      }
    }

    &::before {
      content: '“';
      position: absolute;
      font-family: ${({ theme }) => theme.font.family.secondary};
      font-weight: ${({ theme }) => theme.font.weight.bold};
      font-size: 165px;
      top: -50px;
      left: 0;
      color: ${({ theme }) => theme.color.primary};
    }
  }

  & h2 {
    font-size: 20px;
    line-height: 24px;
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    padding-top: 20px;
  }

  & h3 {
    font-size: 20px;
    line-height: 24px;
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    padding-top: 20px;
  }

  @media screen and (min-width: 992px) {
    padding-right: 100px;
  }

  @media screen and (max-width: 991px) {
    & blockquote {
      padding: 100px 0 20px 0px;
    }
  }
`

const Block = styled.div`
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  background-color: ${({ theme }) => theme.color.greyLight};
  padding: 2rem;
  & h1,
  & h2,
  & h3 {
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.extraBold};
    color: ${({ theme }) => theme.color.darkish};
    font-size: 26px;
    line-height: 31px;
  }

  & p {
    font-size: 16px;
    line-height: 22px;
    font-weight: ${({ theme }) => theme.font.weight.light};
    text-align: justify;
    margin-bottom: 20px;
  }

  @media screen and (min-width: 992px) {
    padding: 30px;
    max-width: 450px;
    margin: -8rem auto 0;
  }
`

const ShareWrapper = styled.div`
  position: absolute;
  top: -40px;
`

const WithSidebar: React.FC<WithSidebarProps> = ({
  location,
  pageContext,
  fields,
}) => {
  const post = usePost()

  return (
    <section id={fields?.anchorId || ''} className="pt-lg-4">
      <div className="container py-3 py-lg-5">
        <div className={`row justify-content-${fields.alignment}`}>
          <StyledWithFragments
            className={`position-relative pb-lg-0 pb-4 col-lg-${fields.width}`}
          >
            {Object.keys(post).length !== 0 && (
              <ShareWrapper>
                <Share
                  body={`Bekijk dit klantverhaal van Veerman ICT! ${location.href}`}
                  subject="Veerman ICT"
                  location={location}
                />
              </ShareWrapper>
            )}
            <ParseContent content={fields.description} />
            {fields?.sortText && fields?.sortText === 'with_image' && (
              <div className="py-3 py-lg-5">
                {fields?.content?.map((item, index) => (
                  <div
                    key={index}
                    className="row py-3 py-lg-5 align-items-center"
                  >
                    <div
                      className={`col-lg-5 ${
                        item?.type === 'image_right' && 'order-lg-2'
                      }`}
                    >
                      <Plaatjie image={item?.image} alt="" />
                    </div>
                    <div className="col-lg-7">
                      <ParseContent content={item?.description} />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </StyledWithFragments>
          <div
            className={`col-lg-${12 - (fields.width || 11)} position-relative`}
          >
            <Block>
              <ParseContent content={fields?.sidebar} />
              {fields?.formid && (
                <StyledFormDuuf
                  id={Number(fields?.formid) || 22}
                  className="mt-4 pt-2"
                />
              )}
            </Block>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WithSidebar
