import React from 'react'
import ParseContent from 'components/shared/ParseContent'

// Components
import Plaatjie from '@ubo/plaatjie'

// Third Party
import styled from 'styled-components'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import ButtonOutline from 'components/elements/Buttons/ButtonOutline'

interface BannerEventProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
  pageContext?: any
}

const StyledBannerEvent = styled.section``

const BannerWrapper = styled.div`
  position: relative;
  height: fit-content;
  min-height: 400px;
`

const ImageWrapper = styled.div`
  position: relative;
  height: 500px;
  z-index: -1;

  @media screen and (max-width: 991px) {
    height: 300px;
  }
`

const StyledImage = styled(Plaatjie)`
  height: 100%;
  border-bottom-left-radius: 180px;

  @media screen and (max-width: 991px) {
    border-bottom-left-radius: 90px;
  }
`

const ContentWrapper = styled.div`
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 10;
  color: ${(props) => props.theme.color.grey};
`

const Block = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  background-color: ${({ theme }) => theme.color.light};

  & h1,
  h2,
  h3 {
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.extraBold};
    color: ${({ theme }) => theme.color.darkish};
    font-size: 26px;
    line-height: 37px;
  }

  & p {
    font-size: 16px;
    line-height: 22px;
    font-weight: ${({ theme }) => theme.font.weight.light};
    text-align: justify;
    margin-bottom: 10px;

    @media (max-width: 575px) {
      margin: 0;
      font-size: 14px;
    }
  }

  & a {
    margin-top: 15px;
  }

  @media screen and (min-width: 992px) {
    margin-top: 60px;
    padding: 30px;
    max-width: 551px;
  }

  @media screen and (max-width: 991px) {
    padding: 20px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  }
`

const StyledButtonPrimary = styled(ButtonPrimary)`
  & a {
    padding: 10px 30px;
    border: 1px solid ${({ theme }) => theme.color.primary};
  }

  @media (max-width: 991px) {
    width: 100%;
  }
`

const StyledButtonOutline = styled(ButtonOutline)`
  & a {
    padding: 10px 30px;
    border: 1px solid ${({ theme }) => theme.color.primary};
    color: ${({ theme }) => theme.color.primary};
  }

  @media (max-width: 991px) {
    width: 100%;
  }
`

const BannerEvent: React.FC<BannerEventProps> = ({ pageContext, fields }) => (
  <StyledBannerEvent>
    <BannerWrapper>
      <ImageWrapper className="d-flex justify-content-center">
        <StyledImage loading="eager" alt="banner" image={fields?.image} />
      </ImageWrapper>
      <ContentWrapper>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <Block>
                <ParseContent content={fields?.description} className="mb-3" />
                <div
                  className={`d-flex ${
                    fields?.info?.length > 3
                      ? 'flex-wrap align-items-center'
                      : 'flex-column'
                  } justify-content-between`}
                >
                  {fields?.info?.map((info, index) => (
                    <p key={index} className="d-flex align-items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="8"
                        viewBox="0 0 8 8"
                        fill="none"
                      >
                        <circle cx="4" cy="4" r="4" fill="#76B459" />
                      </svg>
                      <span className="ms-2">{info?.item}</span>
                    </p>
                  ))}
                </div>
                <div className="d-flex flex-wrap align-items-center">
                  {fields?.links?.map((link, index) => {
                    if (link?.stijl === 'green') {
                      return (
                        <StyledButtonPrimary
                          key={index}
                          to={link?.link?.url || ''}
                        >
                          {link?.link?.title}
                        </StyledButtonPrimary>
                      )
                    }
                    return (
                      <StyledButtonOutline
                        key={index}
                        to={link?.link?.url || ''}
                        className="ms-lg-3"
                      >
                        {link?.link?.title}
                      </StyledButtonOutline>
                    )
                  })}
                </div>
              </Block>
            </div>
          </div>
        </div>
      </ContentWrapper>
    </BannerWrapper>
  </StyledBannerEvent>
)

export default BannerEvent
